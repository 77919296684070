import React, { useState } from 'react';
import { 
    Box, 
    Button, 
    Container, 
    CssBaseline, 
    Grid, 
    TextField, 
    Typography, 
    Link 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import the Auth context

const Signup = ({ onSignupSuccess }) => {
    const navigate = useNavigate();
    const { login } = useAuth(); // Access the login function from AuthContext
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        cpassword: '',
        contact: '',
    });
    const [error, setError] = useState('');

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate that password and cpassword match
        if (formData.password !== formData.cpassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const response = await fetch('http://13.233.43.240:8088/addUser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: formData.username,
                    email: formData.email,
                    password: formData.password,
                    contact: formData.contact,
                }),
            });

            if (response.ok) {
                const userData = {
                    username: formData.username,
                    email: formData.email,
                    contact: formData.contact,
                };

                // Login the user immediately after signup
                login(userData); // This will log in the user and save them in the context

                // Call the onSignupSuccess callback to trigger any further actions (e.g., payment)
                if (onSignupSuccess) {
                    onSignupSuccess();
                }

                navigate('/');  // Optional: Redirect the user to the homepage or dashboard
            } else {
                const message = await response.text();
                setError(message || 'Signup failed. Please try again.');
            }
        } catch (err) {
            setError('An error occurred. Please try again later.');
        }
    };

    return (
        <Container component="main">
            <CssBaseline />
            <Grid container spacing={1} sx={{ alignItems: 'center' }}>
                <Grid item xs={12}>
                    <Typography variant="h5" sx={{ marginBottom: 2, fontFamily:"Times New Roman, Times, serif", color:"green" }}>
                        SIGN UP
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                value={formData.username}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="cpassword"
                                label="Confirm Password"
                                type="password"
                                id="cpassword"
                                autoComplete="new-password"
                                value={formData.cpassword}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="contact"
                                label="Contact Number"
                                name="contact"
                                autoComplete="tel"
                                value={formData.contact}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    {error && (
                        <Typography color="error" variant="body2" align="center">
                            {error}
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, bgcolor: 'green', '&:hover': { bgcolor: 'darkgreen' }, fontFamily: "Times New Roman, Times, serif" }}
                        onClick={handleSubmit}  // Call handleSubmit
                    >
                        Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/" variant="body2" sx={{ color: 'green', fontFamily: "Times New Roman, Times, serif" }}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Signup;
