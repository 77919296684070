import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Modal,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PictureAsPdfRoundedIcon from "@mui/icons-material/PictureAsPdfRounded";
import axios from "axios";
import { useAuth } from "./AuthContext";
import Signup from "./Signup";
// React PDF Viewer imports
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2196f3",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

const HomePage = () => {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user, isLoggedIn } = useAuth(); // Assuming you're using AuthContext
  const [demoPdfUrl, setDemoPdfUrl] = useState(null); // For storing demo PDF URL
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open state
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false); // Modal for login/signup
  const navigate = useNavigate();
  const [selectedBookForPayment, setSelectedBookForPayment] = useState(null);
  const [isSignupDialogOpen, setIsSignupDialogOpen] = useState(false);
  
  const [filters, setFilters] = useState({
    materialType: "",
    chapterName: "",
    status: "",
    categoryName: "",
    subcategoryName: "",
  });
  const handleSignupSuccess = () => {
    setIsSignupDialogOpen(false); // Close signup dialog
    if (selectedBookForPayment) {
      console.log("Proceeding to payment for:", selectedBookForPayment);
      isLoggedIn(true);
      handlePayment(selectedBookForPayment); // Continue with payment
    }
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin(); // PDF viewer layout

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const response = await axios.get(
          "http://13.233.43.240:8088/getallMaterial"
        );
        setBooks(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching books:", error);
        setLoading(false);
      }
    };

    fetchBooks();
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const filteredBooks = books.filter((book) => {
    return (
      (filters.materialType === "" ||
        book.materialtype.includes(filters.materialType)) &&
      (filters.chapterName === "" ||
        book.chapterName.includes(filters.chapterName)) &&
      (filters.status === "" || book.status.includes(filters.status)) &&
      (filters.categoryName === "" ||
        book.categoryName.includes(filters.categoryName)) &&
      (filters.subcategoryName === "" ||
        book.subcategoryName.includes(filters.subcategoryName))
    );
  });

  const handlePayment = (book) => {
    console.log("Handle payment called for book:", book); // Log the book details

    // Ensure that the user is logged in
    if (!isLoggedIn) {
      console.log("User is not logged in, opening signup dialog.");
      setSelectedBookForPayment(book); // Save the selected book for later payment
      setIsSignupDialogOpen(true); // Open signup dialog
      return;
    }

    if (!window.Razorpay) {
      alert("Razorpay SDK not loaded. Please refresh the page and try again.");
      return;
    }

    const finalAmount = book.price * 100; // Convert to paisa for Razorpay
    console.log("Final amount for payment:", finalAmount); // Log final amount

    const options = {
      key: "rzp_test_vv1FCZvuDRF6lQ", // Razorpay Key
      amount: finalAmount, // Amount in paisa
      currency: "INR",
      name: "MAHASTUDY",
      description: "Plan Subscription",
      handler: async function (response) {
        alert(
          `Payment Successful. Payment ID: ${response.razorpay_payment_id}`
        );

        try {
          const addResponse = await axios.post("http://13.233.43.240:8088/add", {
            username: user.username,
            email: user.email,
            materialId: book.id,
            paidAmount: finalAmount,
          });

          console.log("User material association added:", addResponse.data);

          const link = document.createElement("a");
          link.href = book.pdfFile;
          link.download = "ebook.pdf";
          link.click();
        } catch (error) {
          console.error("Error posting user material association:", error);
          alert("An error occurred while processing your request.");
        }
      },
      prefill: {
        name: user.username,
        email: user.email,
        contact: user.contact || "1234567890",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#003366",
      },
    };

    console.log("Razorpay options:", options); // Log options before opening

    const rzp = new window.Razorpay(options);
    rzp.on("payment.failed", function (response) {
      alert(`Payment failed. Reason: ${response.error.description}`);
    });

    console.log("Opening Razorpay payment gateway...");
    rzp.open(); // Open Razorpay
  };

  const handleDemoClick = (pdfUrl) => {
    setDemoPdfUrl(pdfUrl);
    setIsModalOpen(true); // Open the modal with the PDF viewer
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setDemoPdfUrl(null); // Reset the PDF URL
  };

  const handleLoginClick = () => {
    navigate("/login"); // Navigate to the login page
    setIsAuthModalOpen(false); // Close the modal
  };

  const handleSignUpClick = () => {
    navigate("/signup"); // Navigate to the signup page
    setIsAuthModalOpen(false); // Close the modal
  };
  const handleSignupDialogClose = () => {
    setIsSignupDialogOpen(false);
  };
  useEffect(() => {
    if (isLoggedIn && selectedBookForPayment) {
      handlePayment(selectedBookForPayment);
    }
  }, [isLoggedIn]); // You can trigger this when the user logs in after signup

  if (loading) {
    return (
      <Box sx={{ textAlign: "center", mt: 5 }}>
        <Typography variant="h6">Loading...</Typography>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* Filter Text Fields */}
        <Box sx={{ mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={2.4}>
              <TextField
                fullWidth
                label="Material Type"
                name="materialType"
                value={filters.materialType}
                onChange={handleFilterChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
              <TextField
                fullWidth
                label="Chapter Name"
                name="chapterName"
                value={filters.chapterName}
                onChange={handleFilterChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
              <TextField
                fullWidth
                label="Status"
                name="status"
                value={filters.status}
                onChange={handleFilterChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
              <TextField
                fullWidth
                label="Category"
                name="categoryName"
                value={filters.categoryName}
                onChange={handleFilterChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2.4}>
              <TextField
                fullWidth
                label="Subcategory"
                name="subcategoryName"
                value={filters.subcategoryName}
                onChange={handleFilterChange}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Box>

        {/* Display Books */}
        <Grid container spacing={3} mt={1}>
          {filteredBooks.map((book, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card
                sx={{
                  height: "87%",
                  padding: "8px",
                  transition: "transform 0.2s",
                  "&:hover": { transform: "scale(1.05)" },
                }}
              >
                <CardMedia
                  component="img"
                  alt={book.title}
                  image={book.thumbnailFile}
                  sx={{
                    height: 150,
                    objectFit: "cover",
                    opacity: 1,
                    transition: "opacity 0.2s",
                    "&:hover": { opacity: 0.7 },
                  }}
                  onClick={() => window.open(book.thumbnailFile, "_blank")}
                />
                <CardContent sx={{ padding: "8px" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "16px", fontWeight: "bold" }}
                  >
                    {book.materialtype}{" "}
                    <span
                      style={{
                        color: book.status === "Available" ? "green" : "red",
                      }}
                    >
                      ({book.status})
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="purple"
                    sx={{ fontSize: "14px" }}
                  >
                    {book.chapterName}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "green",
                      }}
                    >
                      Rs.{book.price}
                    </Typography>
                    <Typography
                      sx={{
                        textDecoration: "line-through",
                        color: "red",
                        fontSize: "12px",
                      }}
                    >
                      Rs.{book.mrp}
                    </Typography>
                  </Box>
                  <Grid container spacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={6}>
                      <Button
                        size="small"
                        variant="contained"
                        color="error"
                        onClick={() => handleDemoClick(book.demoPdf)}
                        startIcon={<PictureAsPdfRoundedIcon />}
                      >
                        Demo
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => handlePayment(book)}
                      >
                        Buy
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* PDF Viewer Modal */}
        <Modal open={isModalOpen} onClose={closeModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              height: "80%",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              overflow: "auto",
            }}
          >
            <Typography variant="h6" component="h2" gutterBottom>
              Demo PDF Viewer
            </Typography>
            {demoPdfUrl && (
              <Worker
                workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
              >
                <Viewer
                  fileUrl={demoPdfUrl}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </Worker>
            )}
            <Button variant="contained" color="secondary" onClick={closeModal}>
              Close
            </Button>
          </Box>
        </Modal>

        {/* Login/Signup Modal */}
        {/* Signup Dialog */}
        <Dialog
          open={isSignupDialogOpen}
          onClose={handleSignupDialogClose}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Sign Up</DialogTitle>
          <DialogContent>
            <Signup onSignupSuccess={handleSignupSuccess} />{" "}
            {/* Pass the callback */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSignupDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default HomePage;
