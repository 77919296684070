
import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Purchase from "./components/Purchase";
import Signup from "./components/Signup";
import HomePage from "./components/HomePage";
import Login from "./components/Login";
import Layout from "./components/Layout";
import { AuthProvider } from "./components/AuthContext"; // Import AuthContext

const theme = createTheme({
  typography: {
    fontFamily: '"Times New Roman", Times, serif',
  },
});

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {/* Ensure that everything is inside the Router */}
        <AuthProvider> {/* Provide Auth Context */}
          <Router>
            <div>
              <Routes>
                {/* Automatically redirect to HomePage */}
                <Route path="/" element={<Navigate to="/layout/home" />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />

                <Route path="/layout/*" element={<Layout />}>
                  <Route path="home" element={<HomePage />} />
                  <Route path="purchase" element={<Purchase />} />
                </Route>
              </Routes>
            </div>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;

