import React, { useState } from "react";
import { Box, Typography, Link, Grid, Dialog, DialogContent, DialogTitle } from "@mui/material";

// Your policies data in JSON format
const policies = {
  "terms-and-conditions": {
    title: "Terms & Conditions",
    content: `
      These are the Terms & Conditions for using PJSOFTTECH PVT. LTD.  By accessing or using our platform, you agree to comply with and be bound by these terms:
      
      1. Use of the Service:
        - You must be at least 18 years old to use our services.
        - You agree not to misuse the service or violate any applicable laws while using it.
        
      2. Intellectual Property:
        - All content available on the platform, including eBooks, images, and text, are the intellectual property of PJSOFTTECH PVT. LTD. or its licensors.
        - You may not copy, distribute, or modify any of the content without prior permission.

      3. Account Responsibilities:
        - You are responsible for maintaining the confidentiality of your account credentials.
        - You are also responsible for any activities that occur under your account.
        
      4. Payment & Refunds:
        - Payments for eBooks and other services must be completed through our payment processors.
        - Refunds are subject to the terms outlined in our Refund Policy.

      5. Limitation of Liability:
        - PJSOFTTECH PVT. LTD. is not responsible for any indirect, incidental, or consequential damages arising from the use of the platform.

      6. Modifications to the Terms:
        - We reserve the right to modify these terms at any time. We will notify you of significant changes by posting updates on our site.
    `
  },
  "privacy-policy": {
    title: "Privacy Policy",
    content: `
      At PJSOFTTECH PVT. LTD., we value your privacy and are committed to protecting your personal information. 
      This Privacy Policy outlines the types of data we collect, how we use it, and your rights regarding your personal data.
      
      1. Information We Collect:
        - Personal Information: When you create an account, make a purchase, or contact us, we may collect information such as your name, email address, billing and shipping addresses, and payment details.
        - Usage Data: We collect information on how you use the application, including your interactions with eBooks, reading history, and device information.
        - Cookies and Tracking Technologies: We may use cookies and other tracking technologies to improve your experience on our site and for analytics purposes.
        
      2. How We Use Your Information:
        - Provide and improve our eBook services.
        - Process transactions and communicate with you regarding your purchases.
        - Personalize your reading experience based on your preferences.
        - Send promotional offers and updates, which you can opt-out of at any time.
        - Analyze usage trends to improve our offerings.
        
      3. Data Sharing and Security:
        - We do not sell your personal information. We may share your data with trusted third-party service providers to help us operate our app (e.g., payment processors), but these providers are bound by confidentiality agreements.
        - We implement appropriate security measures to protect your data from unauthorized access, alteration, or disclosure.
        
      4. Your Rights:
        - You have the right to:
          - Access the personal information we hold about you.
          - Request correction or deletion of your data.
          - Opt-out of marketing communications.
          - Withdraw your consent to the processing of your data.
        
      5. Data Retention:
        - We retain your personal data only as long as necessary to fulfill the purposes outlined in this policy or as required by law.
        
      6. Changes to This Privacy Policy:
        - We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website and updating the date at the top.
        
      7. Contact Us:
        - If you have any questions or concerns regarding this Privacy Policy, please contact us at +9923570901.
    `
  },
  "shipping-policy": {
    title: "Shipping Policy",
    content: `
      This is the Shipping Policy for PJSOFTTECH PVT. LTD.:
      
      1. Shipping Methods:
        - We offer various shipping methods to deliver physical products (if applicable) purchased through our platform.
        - Shipping fees and delivery times depend on your location and the chosen shipping method.
      
      2. Shipping Timeline:
        - Orders are processed within [Processing Time] business days.
        - Delivery times typically range from 4 to 8 business days based on your location and shipping method.

      3. International Shipping:
        - International shipping may be available depending on your location.
        - Additional customs fees or taxes may apply based on your country's regulations.

      4. Lost or Damaged Items:
        - If an item is lost or damaged during shipping, please contact us at +9923570901 within 2 days of the expected delivery date.
    `
  },
  "refund-policy": {
    title: "Refund Policy",
    content: `
      This is the Refund Policy for PJSOFTTECH PVT. LTD. :
      
      1. Eligibility for Refunds:
        - Refunds can be requested for digital or physical products within 2 days of purchase if the product is defective or not as described.
        - Refund requests for eBooks may be considered under specific circumstances, such as accidental duplicate purchases.
        
      2. How to Request a Refund:
        - To request a refund, please contact us at +9923570901 with your order number and reason for the refund.
        - Refunds will be processed through the original payment method within 5 days of approval.

      3. Non-Refundable Items:
        - Certain items, such as promotional or discounted products, may not be eligible for a refund.
    `
  },
  "contact-us": {
    title: "Contact Us",
    content: `
      If you have any queries, feel free to contact us at:
      
      - Email: info@pjsofttech.com
      - Phone: +9923570901
      - Address: 26, Bhagyanagri Society, Lokmanya Bal Gangadhar Tilak Rd, near Hotel, Ramashram Society, Rukmini Sadan Society, Perugate, Sadashiv Peth, Pune, Maharashtra 411030

      We aim to respond to all inquiries within 2 days.
    `
  }
};


const FooterBar = () => {
  const [open, setOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState({ title: "", content: "" });

  const handleClickOpen = (policyKey) => {
    setSelectedPolicy(policies[policyKey]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#e0ffe0",
        padding: "20px 0",
        borderTop: "1px solid #e0e0e0",
        ml: 30,
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={2} textAlign="center">
          <Link onClick={() => handleClickOpen("terms-and-conditions")} underline="hover" color="inherit">
            <Typography variant="body1">Terms & Conditions</Typography>
          </Link>
        </Grid>
        <Grid item xs={12} md={2} textAlign="center">
          <Link onClick={() => handleClickOpen("privacy-policy")} underline="hover" color="inherit">
            <Typography variant="body1">Privacy Policy</Typography>
          </Link>
        </Grid>
        <Grid item xs={12} md={2} textAlign="center">
          <Link onClick={() => handleClickOpen("shipping-policy")} underline="hover" color="inherit">
            <Typography variant="body1">Shipping Policy</Typography>
          </Link>
        </Grid>
        <Grid item xs={12} md={2} textAlign="center">
          <Link onClick={() => handleClickOpen("refund-policy")} underline="hover" color="inherit">
            <Typography variant="body1">Refund Policy</Typography>
          </Link>
        </Grid>
        <Grid item xs={12} md={2} textAlign="center">
          <Link onClick={() => handleClickOpen("contact-us")} underline="hover" color="inherit">
            <Typography variant="body1">Contact Us</Typography>
          </Link>
        </Grid>
      </Grid>
      <Box textAlign="center" mt={2}>
        <Typography variant="body2">
          PJSOFTTECH Pvt. Ltd.{' '}
          <Typography component="span" variant="body2" sx={{ color: 'red' }}>
            © All Rights Reserved
          </Typography>
        </Typography>
      </Box>

      {/* Dialog for showing policy content */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>{selectedPolicy.title}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" sx={{ whiteSpace: "pre-line" }}>
            {selectedPolicy.content}
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default FooterBar;
