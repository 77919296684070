import React, { useState } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,

} from "@mui/material";
import HomeSharpIcon from "@mui/icons-material/HomeSharp";

import LocalMallIcon from "@mui/icons-material/LocalMall";

import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme } from "@mui/material/styles";


// Define the theme
const theme = createTheme();

export default function Sidebar({ mobileOpen, handleDrawerToggle }) {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openAddOptions, setOpenAddOptions] = useState(false); // State to manage additional options

  const toggleAddOptions = () => {
    setOpenAddOptions((prev) => !prev);
  };

  const drawer = (
    <Box sx={{ width: 250 }}>
      <List>
        <ListItem
          disablePadding
          sx={{
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.05)",
              backgroundColor: "#dce775", // Light yellow-green on hover
            },
          }}
        >
          <ListItemButton component={Link} to="/layout/home">
            <ListItemIcon>
              <HomeSharpIcon sx={{ color: "green" }} />
            </ListItemIcon>{" "}
            {/* Yellowish-green color */}
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
      </List>

      <List>
        <ListItem
          disablePadding
          sx={{
            transition: "transform 0.2s",
            "&:hover": {
              transform: "scale(1.05)",
              backgroundColor: "#dce775", // Light yellow-green on hover
            },
          }}
        >
          <ListItemButton component={Link} to="/layout/purchase">
            <ListItemIcon>
              <LocalMallIcon sx={{ color: "green" }} />
            </ListItemIcon>
            <ListItemText primary="Purchase" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box component="nav">
      {isMobile ? (
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": {
              width: 250,
              top: "64px",
              height: "calc(100% - 64px)",
            },
          }}
        >
          {drawer}
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          open
          sx={{
            "& .MuiDrawer-paper": {
              width: 250,
              top: "64px",
              height: "calc(100% - 64px)",
            },
          }}
        >
          {drawer}
        </Drawer>
      )}
    </Box>
  );
}
