import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { Box, Typography } from '@mui/material';
import FooterBar from './FooterBar';

const Layout = () => {
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
      <Navbar />
      
      {/* Sidebar and content area container */}
      <Box sx={{ display: 'flex', flexGrow: 1,}}>
        <Sidebar />
        
        {/* Main content area */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            ml: { sm: '250px' }, // Adjust for sidebar width
            mt: '30px', // Navbar height
          }}
        >
          <Outlet /> {/* This will render the nested routes */}
        </Box>
      </Box>

      {/* Footer section */}
      <Box
        sx={{
          backgroundColor: 'background.default',
          py: 2, // Padding top and bottom
          textAlign: 'center',
          borderTop: '1px solid #e0e0e0',
        }}
      >
       <FooterBar />
      </Box>
    </Box>
  );
};

export default Layout;
