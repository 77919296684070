import React, { useState } from 'react';
import {
  createTheme,
  ThemeProvider,
  styled,
  alpha,
} from '@mui/material/styles';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  InputBase,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Avatar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext'; // Import useAuth from your AuthContext

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3', // Blue color for primary theme
    },
    secondary: {
      main: '#ffffff', // White for contrast
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'linear-gradient(to right, #d4e157, #9ccc65)', // Gradient background
          opacity: 1, // Make it non-transparent
        },
      },
    },
  },
});

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  marginRight: theme.spacing(2),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // Vertical padding + font size from search icon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function Navbar({ handleDrawerToggle }) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const navigate = useNavigate();

  const { isLoggedIn, logout } = useAuth(); // Use AuthContext for authentication state

  const [anchorEl, setAnchorEl] = useState(null); // Anchor element for the menu
  const open = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget); // Set anchor element to open the menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
  };

  const handleLogout = () => {
    logout(); // Call logout from AuthContext
    handleMenuClose();
    navigate('/login'); // Redirect to the login page
  };

  const handleLoginClick = () => {
    navigate('/login'); // Navigate to the login page
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed">
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* Left Side: Menu icon (if mobile) and App name */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {isMobile && location.pathname === '/' && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2 }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <Typography
                variant="h5"
                textAlign="center"
                sx={{
                  color: 'green',
                  fontFamily: 'Poppins, Helvetica, sans-serif',
                  fontWeight: 'bold',
                }}
              >
                EBook Application
              </Typography>
            </Box>

            {/* Right Side: Search bar and Login button or Profile Avatar */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ 'aria-label': 'search' }}
                />
              </Search> */}

              {/* Conditionally render Login button or Profile Avatar */}
              {!isLoggedIn ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLoginClick} // Updated handler to navigate to login
                  sx={{ marginLeft: 2 }}
                >
                  Login
                </Button>
              ) : (
                <>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls="profile-menu"
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                    color="inherit"
                  >
                    <Avatar alt="Profile Picture" src="/path-to-profile-image.jpg" />
                  </IconButton>
                  <Menu
                    id="profile-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleMenuClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </>
              )}
            </Box>
          </Toolbar>
        </AppBar>

        {/* Main content section with spacing from Navbar */}
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {/* Add your main content here */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
